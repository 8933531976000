body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.config-nav-item {
  transition: all 0.2s;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.theme-background {
  background: #000000;
}

.theme-title {
  color: white;
}

.main-app-container {
  display: flex;
  justify-content: center;
  overflow: auto;
  height: 100vh;
  backface-visibility: hidden;
  position: relative;
  will-change: overflow;
}

.nButton:hover {
  background: rgb(43, 43, 43);
  cursor: pointer;
}

@media (max-width: 1200px) {
  .navigator-menu .base-text {
    opacity: 0.6 !important;
    display: block !important;
  }

  .tablet-text-hide text {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .navigator-menu:hover .base-text {
    display: block !important;
    left: 9px;
    position: relative !important;
    bottom: 0 !important;
    opacity: 1 !important;
  }
  
  .navigator-menu:hover .nButton {
    flex-direction: row !important;
  }

  .navigator-menu:hover {
    max-width: 136px !important;
  }
}

.mcButton:hover {
  background: rgb(71 71 71);
}

.scroll-view {
  overflow: auto;
  height: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}

.switch-btn.inactive:hover {
  background: transparent !important;
  opacity: 1 !important;
  box-shadow: 'inset 0 0 2px 1px black' !important;
}

.scroll-view::-webkit-scrollbar {
  display: none;
}

.horizontal-scroll-view {
  overflow: auto;
  width: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  white-space: nowrap;
}

input::placeholder {
  color: #a6a6a6;
  opacity: 1;
}

.blue-outline-hover {
  border-radius: 10px;
  border: 3px solid transparent;
}

.blue-outline-hover:hover {
  border: 3px solid #5797F8
}

.simple-hover:hover {
  opacity: 0.7;
  cursor: pointer;
}

/*bookings calendarView*/
.calendarWrapper {
  color: #f6f6f6 !important;
}

.dailyTimeLineWrapper {
  height: 1000px !important;
  overflow-y: hidden !important;
}

.dailyEventWrapper {
  cursor: pointer;
}

/*Modal*/
.ReactModal__Overlay {
  z-index: 15;
}

.shade-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
  background: #121212;
  overflow: clip;
}

.shade-table th, .shade-table td {
  padding: 14px;
  text-align: left;
  font-size: 12px;
}

.shade-table .td-no-padding {
  padding: 0 !important;
}

.shade-table thead {
  background: #2B2B2B;
  color: white;
}

.shade-table tr {
  color: white;
  border-bottom: 0.1rem solid white;
}

.shade-table tbody tr:hover {
  background-color: #380000;
}